function removeVariant(){
  $("body").on("click", "#remove-variant", function(event) {
    event.preventDefault();
    $("#variant-added").remove();
    $("#new-kit-variant").show();
    $("#remove-variant").toggleClass("remove-variant__hidden");
  });
}

document.addEventListener("turbolinks:load", function() {
  removeVariant();
});
