import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "quantityselector" ]

  initialize(){
  }

  selectSize(event){
    event.preventDefault()
    let id = event.currentTarget.value
    let choice = document.getElementById(id)
    this.quantityselectorTargets.forEach(function(selector){
      selector.classList.add('d-none')
      selector.classList.remove('d-flex')
    })

    choice.classList.remove('d-none')
    choice.classList.add('d-flex')
  }

}