import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "logo", "container", "image", "logoerror", "imageerror", "newfiles", "fileserror"]

  initialize(){
    console.log('init fileupload')
  }

  updatepreview(e){
    const preview = this.logo
    const file = e.currentTarget.files[0]
    const reader = new FileReader()

    let controller = this

    reader.addEventListener("load", function () {
      preview.innerHTML = ''
      var image = new Image()
      image.src = String(reader.result)
      image.classList.add('img-fluid')
      image.classList.add('avatar-img')

      controller.imageExists(image.src, function(exists){
        if (exists) {
          preview.appendChild(image)
          controller.logoError.classList.add('d-none')
        } else {
          controller.logoError.classList.remove('d-none')
        }
      })
    }, false)

    if (file) {
      reader.readAsDataURL(file)
    }
    // when error message display will be ready we can do that :
    // this.uploadFile()
  }

  imageExists(url, callback) {
    let img = new Image()
    img.onload = function() { callback(true) }
    img.onerror = function() { callback(false) }
    img.src = url
  }

  updatepreviewimage(e){
    const preview = this.image
    const file = e.currentTarget.files[0]
    const reader = new FileReader()

    let controller = this

    reader.addEventListener("load", function () {
      preview.innerHTML = ''
      var image = new Image()
      image.src = String(reader.result)
      image.classList.add('img-fluid')
      image.classList.add('rounded')
      controller.imageExists(image.src, function(exists){
        if (exists) {
          preview.appendChild(image)
          controller.imageError.classList.add('d-none')
        } else {
          controller.imageError.classList.remove('d-none')
        }
      })
    }, false)

    if (file) {
      reader.readAsDataURL(file)
    }
    // when error message display will be ready we can do that :
    //this.uploadFile()
  }

  updatepreviewkitimages(e){
    console.log('updatepreviewkitimages')
    const preview = this.newfiles
    const files = e.currentTarget.files

    let controller = this

    if (files) {
      if(files.length > 3){
        alert("You can select up to 3 images.");
        return false;
      }
      [].forEach.call(files, readAndPreview);
    }

    let errors = 0

    function readAndPreview(file) {
      console.log(file.name)
      console.log(file.type)

      const reader = new FileReader()
      reader.addEventListener("load", function () {
        // let theFirstChild = preview.firstChild
        preview.innerHTML = ''
        var image = new Image()
        image.src = String(reader.result)
        image.classList.add('img-fluid')
        image.classList.add('rounded')
        controller.imageExists(image.src, function(exists){
          if (exists) {
              preview.appendChild(image)
          } else {
            // controller.imageError.classList.remove('d-none')
            errors++
          }
        })
      }, false)
      reader.readAsDataURL(file)
    }
  }


  uploadFile(){
    let form = this.container.querySelector('form')
    Rails.fire(form, 'submit');
  }

  // private
  
  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }

  get logo() {
    return this.logoTarget
  }

  get image() {
    return this.imageTarget
  }

  get newfiles() {
    return this.newfilesTarget
  }

  get container() {
    return this.containerTarget
  }

  get logoError() {
    return this.logoerrorTarget
  }

  get imageError() {
    return this.imageerrorTarget
  }

  get filesError(){
    return this.fileserrorTarget
  }
}
