import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

import Rails from '@rails/ujs'
import { debounce } from 'lodash'
import ApplicationController from './application_controller'


let lastCommentId
const reload = controller => {
  controller.stimulate('CommentReflex#reload')
}
const debouncedReload = debounce(reload, 100)

export default class extends ApplicationController {

  static targets = ['input']

  connect() {
    super.connect()
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'CommentChannel',
        kit_id: document.getElementById("kit-comments").getAttribute('data-kitid') 
      },
      {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  post(event) {
    Rails.stopEverything(event)
    lastCommentId = this.element.dataset.commentid
    const current_user_id = this.getMetaValue("current-user-id")
    this.stimulate(
      'CommentReflex#post',
      this.element.dataset.kitid,
      this.element.dataset.clubid,
      this.inputTarget.value,
      current_user_id
    )
    this.afterPost()
  }

  afterPost() {
    this.inputTarget.value = ''
    this.inputTarget.focus()
  }

  delete(event) {
    Rails.stopEverything(event)
    let confirmDialog = confirm("Are you sure ?")
    if (confirmDialog == true) {
      let current_user_id = this.getMetaValue("current-user-id")
      let comment_id = event.currentTarget.dataset.id
      this.stimulate(
        'CommentReflex#delete',
        this.element.dataset.kitid,
        comment_id,
        current_user_id
      )
    }
  }

  reload(event) {
    const { commentId } = event.detail
    if (commentId === lastCommentId) return
    debouncedReload(this)
  }

  toggletrash(event) {
    Rails.stopEverything(event)
    let trash_id = event.currentTarget.dataset.id
    document.getElementById(trash_id).classList.toggle('d-none')
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
