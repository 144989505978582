import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "query", "results"]

  disconnect() {
    this.reset()
  }

  connect() {
  }

  update() {
    this.load()
  }

  load() {
    let the_results = this.resultsTarget
    the_results.innerHTML = ""
    if(this.query == "") {
      this.reset()
      return
    }

    if(this.query == this.previousQuery) {
      return
    }
    this.previousQuery = this.query

    fetch("/api/v1/users?query=" + this.query + "&club_id=" + this.queryTarget.id)
      .then(response => response.text())
      .then(res => {
        if(res == false) {
          this.resultsTarget.textContent = "Sorry, no result."
        } else {
          this.resultsTarget.innerHTML = res
        }
      })
      .catch((message) => { console.log("error : " + message)})
  }

  resetdata() {
    this.reset()
  }

  // private

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
  }

  get query() {
    return this.queryTarget.value
  }
}
