import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "code",  "autocomplete", "country", "searchblock"]

  connect() {
    this.searchblockstatus()
  }

  search() {
    this.load()
  }

  searchblockstatus(){
    this.showSearchButton(this.countryTarget.value)
  }

  showSearchButton(country){
    this.searchblockTarget.classList.toggle("searchaddress--show", country == "jp")
  }

  get code() {
    return this.codeTarget.value
  }

  load() {
    fetch("/api/v1/address?postal_code="+this.code)
      .then(response => response.json())
      .then(res => {
        if(res == false) {
          this.autocompleteTarget.textContent = "Sorry, no result."
        } else {
          this.autocompleteTarget.textContent = "Auto-complete"
          document.getElementById("state-county-prefecture").value = res.table.state_county_prefecture
          document.getElementById("town-city").value = res.table.town_city
          document.getElementById("street-address").value = res.table.street_address
        }
      })
  }
}
