import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "main"]

  loadpage(event) {
    const element = event.target
    this.load(element.dataset.user)
  }

  load(user) {
    this.mainTarget.className += ' animated slideInRight';
    fetch("/users/"+user+"/edit")
      .then(response => response.text())
      .then(html => {
          this.mainTarget.innerHTML = html
      })
  }
}

// TO use DO :

// add data-controller="editprofile" to body

// add data-target="editprofile.main" to main

// use AJAX button in show :

// <button class="btn btn-outline-primary" data-action="click->editprofile#loadpage" data-user="<%= @user.id %>">Edit AJAX</button> 
