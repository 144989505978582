import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "colorcode", "currentcolor", "table" ]

  initialize(){
  }

  updateColor(e) {
    let color = e.currentTarget.value
    const color_inputs = this.table.querySelectorAll('.color_code')
    color_inputs.forEach(function(element){
      element.value = color
    })
  }

  selectSize(e) {
    let columnIndex = this.selectColumn(e.currentTarget)
    const rows = this.table.querySelectorAll('tr')
    rows.forEach(function(element, index){
      if (index > 0){
        const col = element.querySelectorAll('td')
        const cols = Array.from(col)
        const elet = cols[columnIndex]
        const inputs = elet.querySelectorAll('input')
        inputs.forEach(function(inp){
          if (e.currentTarget.checked) {
            inp.disabled = false
          } else {
            inp.disabled = true
          }
        })
      }
    })
  }


  selectColumn(eventTarget) {
    const rows = this.table.querySelectorAll('tr')
    const rowsArray = Array.from(rows)
    const rowIndex = rowsArray.findIndex(row => row.contains(eventTarget))
    const columns = Array.from(rowsArray[rowIndex].querySelectorAll('th'))
    const columnIndex = columns.findIndex(column => column.contains(eventTarget))
    return columnIndex
  }

  get colorcode() {
    return this.colorcodeTarget
  }

  get table() {
    return this.tableTarget
  }

  get currentcolor() {
    return this.currentcolorTarget
  }
}
