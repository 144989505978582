import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ["comments", "pagination"]

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(comments => this.processIntersectionEntries(comments), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(comments) {
    comments.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = document.getElementById("previous-items")
    if (next_page == null) { return }
    let url_string = next_page.href

    let url = new URL(url_string);
    let page_param = url.searchParams.get("page");
    if (page_param == null) { return }

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.commentsTarget.insertAdjacentHTML('beforeend', data.comments)
        if (data.pagination !== null) {
          this.paginationTarget.innerHTML = data.pagination
        } else {
          this.paginationTarget.innerHTML = ''
        }
      }
    })
  }
}
