export function createPopover(){
  // popover
  $('[data-toggle=popover][data-popover-content]').popover({
    html : true,
    sanitize: false,
    container: 'body',
    template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
    content: function() {
      var content_id = $(this).attr("data-popover-content");
      var content = $('#' + content_id).children(".popover-body");
      return content.html();
    },
    title: function() {
      var content_id = $(this).attr("data-popover-content");
      return $('#'+content_id).children(".popover-heading").html();
    }
  });
}
