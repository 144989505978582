import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "number", "stock" ]
  // to do : "submitbutton"

  connect(){
    this.current_stock = parseInt(this.stockTarget.textContent)
    //this.current_total = parseInt(document.getElementById("total-quantity").textContent)
  }

  update(){
    let itemQuantity = parseInt(this.numberTarget.value)
    this.updateQuantity(itemQuantity)
    // let btn = document.getElementById('submitbutton')
    // if(itemQuantity > 0) {
    //   btn.classList.remove('d-none')
    // } else {
    //   btn.classList.add('d-none')
    // }
  }

  dec(e) {
    e.preventDefault()
    this.numberTarget.value = parseInt(this.numberTarget.value) - 1
    this.update()
  }

  inc(e) {
    e.preventDefault()
    this.numberTarget.value = parseInt(this.numberTarget.value) + 1
    this.update()
  }

  updateQuantity(itemQuantity) {
    if (itemQuantity >= 0 && 
        parseInt(this.stockTarget.textContent) >= 0 &&
        itemQuantity <= this.current_stock) {
      this.stockTarget.textContent = this.current_stock - itemQuantity
      //document.getElementById("total-quantity").textContent = this.current_total - itemQuantity
    }
  }
}